.hamburgerMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: relative;
}

.hamburgerMenu .line {
  width: 100%;
  height: 3px;
  background-color: #000;
  margin-bottom: 4px;
  transition: 0.5s;
}

.menu {
  display: none;
  min-width: 200px;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  position: absolute;
  top: 3rem;
  right: 2rem;
  border: 3px solid var(--color-gray-3);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 36px;
  padding: 2rem;
  transition: 0.5s;
}

.toggleOn {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  position: absolute;
  top: 3rem;
  right: 2rem;
  background: white;
  border: 3px solid var(--color-gray-3);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 36px;
  padding: 2rem;
  transition: 0.5s;
  z-index: 999;
}

.menuList {
  display: grid;
  place-items: center;
  border: 3px solid var(--color-gray-3);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 0.5rem;
  text-decoration: none;
  color: #000;
  transition: 0.3s;
  background-color: white;
  font-size: 16px;
}

.menuList:hover {
  background: var(--color-gray-3);
  transition: 0.3s;
}

.hamburgerMenu.menuOpen .line:nth-child(1) {
  transform: rotate(45deg) translate(4px, 4px);
  transition: 0.5s;
}

.hamburgerMenu.menuOpen .line:nth-child(2) {
  opacity: 0;
  transition: 0.5s;
}

.hamburgerMenu.menuOpen .line:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -6px);
  transition: 0.5s;
}

/* 上の線 */
.hamburgerMenu .line:nth-child(1) {
  transform: translateY(-4px);
}

/* 真ん中の線 */
.hamburgerMenu .line:nth-child(2) {
  transform: translateY(0);
}

/* 下の線 */
.hamburgerMenu .line:nth-child(3) {
  transform: translateY(4px);
}
