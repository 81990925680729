.taskCount {
  font-size: 24px;
  margin-bottom: 1.5rem;
  font-weight: bold;
  text-align: center;
}
.TaskListWrapper {
  padding-bottom: 0.5rem;
}

.taskCardWrapper {
  width: 600px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 40px;
  border-top: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  box-shadow: 2px 4px 8px 2px rgba(168, 230, 161, 0.8);
  padding: 0 2rem 0 2rem;
  margin-bottom: 1.5rem;
}

.expiredTaskWrapper {
  width: 600px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 40px;
  border-top: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  box-shadow: 2px 4px 8px 2px rgba(146, 216, 255, 0.8);
  padding: 0 2rem 0 2rem;
  margin-bottom: 1.5rem;
}

.taskCardTopWrapper {
  width: 100%;
  min-height: 130px;
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 1rem 0;
}

.taskCardTopWrapper::after {
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  display: inline-block;
  background-color: var(--color-gray-3);
  position: absolute;
  bottom: 0px;
}

.taskCardTop {
  width: 100%;
  min-height: 130px;
  display: flex;
  flex-direction: row;
}

.taskCardTop:hover {
  border-radius: 40px;
  border-left: 1px solid #b5b5b5;
  background: #f4f4f4;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  transition: 0.5s;
  cursor: pointer;
}

.taskCardTitleWrapper {
  width: 50%; /* 親<div>の幅を設定 */
  text-align: center;
  display: grid;
  place-items: center;
  margin-right: 1rem;
  padding: 1rem 0;
  box-sizing: border-box; /* パディングとボーダーを含めてボックスのサイズを計算 */
}

.taskCardTitle {
  font-size: 28px;
  font-weight: bold;
  word-wrap: break-word; /* 長い単語を折り返す */
  word-break: break-word; /* 単語内でも改行 */
  overflow: hidden; /* オーバーフローを隠す */
  white-space: normal; /* 通常の改行を許可 */
}

.taskCardInfo {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.taskCardButtons {
  width: 10%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  gap: 1rem;
}

.button {
  width: 25px;
  height: 25px;
  border-radius: 4px;
  background: var(--color-gray-3);
}

.taskCardBottom {
  height: 170px;
  min-height: 170px;
  padding: 1rem 0;
}
.withoutTask {
  width: 600px;
  min-height: 300px;
  display: grid;
  place-items: center;
  border-radius: 40px;
  border-top: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0 2rem 0 2rem;
  margin-bottom: 1.5rem;
  font-size: 32px;
  font-weight: bold;
}
