.motionWrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}
.taskMessageWrapper {
  width: 90%;
  align-items: center;
}
.yourMessage {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 24px;
}

.message {
  padding: 1rem 1.5rem;
  border-radius: 25px;
  border: 1px solid #c3c3c3;
  background: #fff;
  font-size: 24px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.supporterMessage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 24px;
}
.motionMessageModalTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  overflow: auto;
  height: 500px;
  padding-bottom: 1rem;
}

.motionMessageModalBottom {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}

.messageInput {
  width: 85%;
  height: 3rem;
  border-radius: 15px;
  border: 1px solid #c3c3c3;
  padding-inline-start: 1rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  font-size: 24px;
}

.sendButton {
  padding: 0.4rem;
  border-radius: 4px;
  background: var(--color-pure-white);
  transition: 0.3s;
  font-size: 24px;
}

.sendButton:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  transition: 0.3s;
}

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
