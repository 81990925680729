.settingsWrapper {
  width: 100%;
  height: 50vh;
  display: grid;
  place-items: center;
}

.cardWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50vh;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.settingsCard {
  width: 30%;
  height: 30vh;
  display: grid;
  place-items: center;
  padding: 2rem;
  background: transparent;
  border-radius: 50px;
  box-shadow: 0px 0px 15px rgb(17 21 26 / 15%);
  font-size: 24px;
}

.accountNameWrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.accountInput {
  margin-top: 0.5rem;
  width: 100%;
  background: transparent;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgb(17 21 26 / 15%);
  font-size: 20px;
  border: 0.5px solid var(--color-gray-3);
  padding: 0.5rem;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.sendButtonWrapper {
  display: grid;
  place-items: center;
}
.sendButton {
  width: 30%;
  padding: 0.5rem;
  background: transparent;
  border-radius: 30px;
  box-shadow: 0px 0px 15px rgb(17 21 26 / 15%);
  font-size: 18px;
  transition: 0.2s ease-in-out;
}

.sendButton:hover {
  background-color: var(--color-gray-3);
  color: white;
  transition: 0.2s ease-in-out;
  box-shadow: 6px 6px 18px rgb(17 21 26 / 15%);
}

.logoutButton {
  padding: 2rem;
  background: transparent;
  border-radius: 30px;
  box-shadow: 0px 0px 15px rgb(17 21 26 / 15%);
  font-size: 24px;
  transition: 0.2s ease-in-out;
}

.logoutButton:hover {
  background-color: var(--color-gray-3);
  color: white;
  transition: 0.2s ease-in-out;
  box-shadow: 6px 6px 18px rgb(17 21 26 / 15%);
}
