.messageContainerWrapper {
  width: 100%;
  min-height: 130px;
  border-radius: 40px;
  border-left: 1px solid #b5b5b5;
  background: #f6f6f6;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem 2rem 1rem 2rem;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.messageContainerWrapper:hover {
  border-left: 1px solid #b5b5b5;
  background: #e4e4e4;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
  transition: 0.5s;
  cursor: pointer;
}

.messageContainerTopUser {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.messageContainerTopSupporter {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lastMessage {
  padding: 0.5rem 2rem 0.5rem 2rem;
  background: white;
  border-radius: 20px;
  text-align: center;
  border: 1px solid #c3c3c3;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
}

.messageContainerBottom {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.messageInput {
  width: 85%;
  height: 2rem;
  border-radius: 15px;
  border: 1px solid #c3c3c3;
  padding-inline-start: 1rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.sendButton {
  /* width: 25px;
  height: 25px; */
  padding: 0.4rem;
  border-radius: 4px;
  background: var(--color-pure-white);
  transition: 0.3s;
}

.sendButton:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  transition: 0.3s;
}

.openMessageContainerWrapper {
  width: 100%;
  height: calc(310px - 3rem);
  margin-top: 1rem;
  border-radius: 40px;
  border-left: 1px solid #b5b5b5;
  background: #f6f6f6;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem 2rem 1rem 2rem;
}

.openMessageContainerTop {
  width: 100%;
  /* height: 50%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.openMessageWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.openLastMessageFromSupporter {
  padding: 0.5rem 2rem 0.5rem 2rem;
  background: white;
  border-radius: 20px;
  text-align: center;
  border: 1px solid #c3c3c3;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
}

.openMessageContainerBottom {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.openMessageInput {
  width: 85%;
  height: 2rem;
  border-radius: 15px;
  border: 1px solid #c3c3c3;
  padding-inline-start: 1rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.openSendButton {
  width: 25px;
  height: 25px;
  border-radius: 4px;
  background: var(--color-gray-3);
  transition: 0.3s;
}

.openSendButton:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  transition: 0.3s;
}
