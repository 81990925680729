/* parametersでフォントサイズとかも管理する */
/* Global style */
:root {
  --z-index-modal: 9999;

  --color-main-white: #fff5e6;
  --color-pure-white: #ffffff;
  --color-black: #000000;

  --color-mythril-pale-red-violet: #e6738e;
  --color-mythril-dark-lavender: #6e519c;
  --color-mythril-rackley: #6792ab;
  --color-mythril-eton-blue: #8cbaa9;

  --color-mythril-mellow-apricot: #ffba7d;
  --color-mythril-mellow-apricot-0: #ffe7d2;
  --color-mythril-mellow-apricot-1: #ffd6b2;
  --color-mythril-mellow-apricot-2: #ffcc9f;
  --color-mythril-mellow-apricot-3: #ffc28d;
  --color-mythril-mellow-apricot-4: #ffba7d; /*basic */
  --color-mythril-mellow-apricot-5: #ffb879;
  --color-mythril-mellow-apricot-6: #ffb26e;
  --color-mythril-mellow-apricot-7: #ff9d47;
  --color-mythril-mellow-apricot-8: #fd8b28;
  --color-mythril-mellow-apricot-9: #ff7700;

  --color-gray-0: #ffffff;
  --color-gray-1: #f2f2f2;
  --color-gray-2: #e6e6e6;
  --color-gray-3: #e2e2e2;
  --color-gray-4: #c4c4c4;
  --color-gray-5: #b2b2b2;
  --color-gray-6: #a1a1a1;
  --color-gray-7: #808080;
  --color-gray-8: #4d4d4d;
  --color-gray-9: #000000;

  /* 各種メッセージ */
  --color-error-0: #ffcccc;
  --color-error-1: #f29d9d;
  --color-error-2: #e67373;
  --color-error-3: #e64545;
  --color-error-4: #da1414;
  --color-error-5: #b31010;

  --color-success-0: #cef2f0;
  --color-success-1: #a1e6e2;
  --color-success-2: #73d1cc;
  --color-success-3: #27c2ba;
  --color-success-4: #00b3aa;
  --color-success-5: #00918a;

  --color-warning-0: #fadbbb;
  --color-warning-1: #fac289;
  --color-warning-2: #f2aa61;
  --color-warning-3: #f2993f;
  --color-warning-4: #ed8218;
  --color-warning-5: #cc6600;

  --color-info-0: #c6e4f7;
  --color-info-1: #a1d0f0;
  --color-info-2: #7abce6;
  --color-info-3: #4ea7de;
  --color-info-4: #1f8dd1;
  --color-info-5: #1275b3;

  /* ライトモーダル */
  --color-title-0: #ffcccc;
  --color-title-1: #e8baba;
  --color-message-0: #c6e4f7;
  --color-message-1: #a1d0f0;
  --color-header-0: #c3e3b3;
  --color-header-1: #adc99f;
  --color-body-0: #eeefae;
  --color-body-1: #dadba0;
  --color-role5-0: #edd7ff;
  --color-role5-1: #d5c1e5;

  /* テーマ画面で使用 */
  --color-red-3: #ffa9a9;
  --color-red-4: #ff5252;
  --color-red-5: #ff1d1d;

  --color-orange-3: #ffcd83;
  --color-orange-4: #ffac30;
  --color-orange-5: #fa9600;

  --color-green-3: #a6dd9e;
  --color-green-4: #6ac75e;
  --color-green-5: #3aac2b;

  --color-blue-3: #8dbaf7;
  --color-blue-4: #418cf2;
  --color-blue-5: #0d65df;

  --color-background: rgba(0, 0, 0, 0.8);

  --color-transparent: transparent;

  /* 重要なプロパティ共通化 */
  /* font-size */
  --font-size-12: 12px;
  --font-size-16: 16px;
  --font-size-20: 20px;
  --font-size-24: 24px;
  --font-size-28: 28px;
  --font-size-36: 36px;
  --font-size-48: 48px;
  --font-size-60: 60px;

  /* font-weight */
  --font-weight-light: 300;
  --font-weight-regular: 600;
  --font-weight-bold: 900;

  /* padding */
  --padding-regular: 1rem;

  /* margin */
  --margin-regular: 1rem;

  /* for component */
  /* button */
  --button-font-size: var(--font-size-16);
  --button-font-color: var(--color-pure-white);
  --button-font-weight: var(--font-weight-bold);
  --button-padding: 0.5rem 1rem;
  --button-border-radius: 10%;
  --button-margin: 1rem;

  --button-hover-font-color: var(--color-mythril-mellow-apricot-6);

  /* label */
  --label-background-color: var(--color--white);
  /* --label-border:
  --label-border-radius:
  --label-font-color: */
  --label-font-size-input: var(--font-size-20);
  --label-font-size-textarea: var(--font-size-20);
  /*
  --label-width-short:
  --label-width-medium:
  --label-width-long:
  --label-height-short:
  --label-height-medium:
  --label-height-long: */
  --label-padding: 0.5rem 1rem;
  /* --label-margin:
  --label-back-shadow: */

  /* input */
  --input-background-color: var(--color--white);
  /* --input-border:
  --input-border-radius:
  --input-font-color: */
  --input-font-size: var(--font-size-24);
  /*
  --input-width-short:
  --input-width-medium:
  --input-width-long:
  --input-height-short:
  --input-height-medium:
  --input-height-long: */
  --input-padding: 0.5rem 1rem;
  /* --input-margin:
  --input-back-shadow: */

  /* textarea */
  --textarea-background-color: var(--color--white);
  /* --textarea-border:
  --textarea-border-radius:
  --textarea-font-color: */
  --textarea-font-size: var(--font-size-16);
  /*
  --textarea-width-short:
  --textarea-width-medium:
  --textarea-width-long:
  --textarea-height-short:
  --textarea-height-medium:
  --textarea-height-long: */
  --textarea-padding: 0.5rem 1rem;
}

/* new */
button {
  border: none;
  cursor: pointer;
}

input {
  /* border: none; */
}

textarea {
  /* border: none; */
  padding: 0.5rem 1rem;
  resize: none;
}

ul {
  padding-left: 0;
}
li {
  list-style: none;
  font-size: var(--font-size-24);
}

/* old */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
