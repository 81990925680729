.headerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  gap: 15%;
  margin-bottom: 2rem;
  position: relative;
}

.hamburgerMenu {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.userName {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1.5rem;
  transition: 0.5s;
  cursor: pointer;
}

.userName:hover {
  background: #f6f6f6;
  transition: 0.5s;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
}

.headerTaskTitle {
  display: grid;
  place-items: center;
  width: 250px;
  font-size: 28px;
  font-weight: bold;
}

.menuListWrapper {
  display: flex;
  gap: 3rem;
  width: 1000px;
  justify-content: space-between;
}

.menuWrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.image {
  width: 90px;
  height: auto;
}

@keyframes rotateImage {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.image:hover {
  animation: rotateImage 0.5s linear;
}

.button {
  height: 4rem;
  background-color: transparent;
  padding: 0.5rem;
  transition: 0.3s;
  font-size: 24px;
}
.button:hover {
  background-color: #acedde;
  padding: 0.75rem 1rem;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.selectedMenuItem {
  background-color: #ffffff;
  padding: 0.75rem 1rem;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  position: relative;
}

.selectedMenuItem::after {
  content: "";
  display: block;
  border-bottom: 2px solid var(--color-gray-3);
  position: absolute;
  bottom: -10px;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
}
