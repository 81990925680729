.taskCard {
  height: 75vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  overflow: auto;
}

.taskList {
  height: 200px;
  width: 1000px;
  background: transparent;
  margin: 1rem;
  border-radius: 50px;
  /* box-shadow: 0px 0px 15px rgb(17 21 26 / 15%); */
  display: flex;
  padding: 2rem 0;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
  cursor: pointer;
  border-top: 1px solid #b6b6b6;
  border-left: 1px solid #b6b6b6;
  box-shadow: 6px 6px 4px 0px rgba(0, 0, 0, 0.25);
}

.taskList:hover {
  box-shadow: 6px 6px 18px rgb(17 21 26 / 15%);
  transition: 0.3s ease-in-out;
  background-color: #acedde;
}

.taskListWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.taskTitle {
  width: 50%;
  height: 100%;
  display: grid;
  place-items: center;
  font-size: 32px;
  font-weight: bold;
  padding: 0 4rem 0 4rem;
}

.taskDetail {
  width: 50%;
  height: 100%;
}

.taskTime {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  font-size: 24px;
}

.supporterName {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  font-size: 24px;
}

.withoutTask {
  display: grid;
  place-items: center;
  width: 100%;
  height: 60vh;
  font-size: 36px;
}

.addButton {
  position: fixed;
  bottom: 100px;
  right: 200px;
  width: 80px;
  height: 80px;
  background-color: var(--color-gray-3);
  border-radius: 50%;
  color: #fff;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  line-height: 75px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  transition: 0.2s ease-in-out;
  z-index: 100;
}

.addButton:hover {
  background-color: white;
  color: var(--color-gray-3);
  transition: 0.2s ease-in-out;
  box-shadow: 6px 6px 18px rgb(17 21 26 / 15%);
}
