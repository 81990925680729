.mainWrapper {
  display: grid;
  place-items: center;
  height: 100vh;
}

.mainContentWrapper {
  width: 100%;
  height: 100vh;
  border-radius: 150px;
}

.footerWrapper {
  height: 5vh;
  width: 100%;
  display: grid;
  place-items: center;
}
