.datePickerWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.timeWrapper {
  display: flex;
  gap: 1rem;
}

.custom-textarea {
  width: 400px;
  border-radius: 100px;
  box-shadow: 0px 0px 15px rgb(17 21 26 / 15%);
  resize: none;
  border: none;
  outline: none;
}

.custom-textarea::placeholder {
  color: gray;
}
