.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal {
  width: 1000px;
  min-height: 600px;
  background-color: #ffffff;
  border-radius: 50px;
  padding: 3rem;
  display: grid;
  place-items: center;
}

.modelGap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.modalTop {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.modalTopRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.modalTopLeft {
  width: 50%;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
}

.button {
  width: 20%;
  height: 3rem;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px rgb(17 21 26 / 15%);
  font-size: 20px;
  transition: 0.3s;
}

.button:hover {
  color: var(--color-pure-white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  font-weight: bold;
}
.closeButton {
}

.editButton {
  color: yellowgreen;
}

.deleteButton {
  color: red;
}
.closeButton:hover {
  background-color: var(--color-gray-6);
}

.editButton:hover {
  background-color: yellowgreen;
}

.deleteButton:hover {
  background-color: red;
}
