.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal {
  width: 750px;
  height: 750px;
  background-color: #ffffff;
  border-radius: 50px;
  padding: 3rem;
  display: grid;
  place-items: center;
}

.modelGap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.modalTop {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.modalTopRight {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.modalTopLeft {
  width: 50%;
}

.supporterName {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
}
.supporterTaskTitle {
  width: 100%;
  height: 60px;
  border-radius: 30px;
  border: 1px solid #f5f5f5;
  background: #fff;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem 2rem;
  color: #000;
  font-size: 24px;
  font-weight: 700;
}

.supporterTaskDescription {
  width: 100%;
  height: 300px;
  border-radius: 30px;
  border: 1px solid #f5f5f5;
  background: #fff;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem 2rem;
  color: #000;
  font-size: 24px;
}

.date {
  text-align: center;
  font-size: 18px;
}

.time {
  text-align: center;
  font-size: 18px;
}

.duration {
  text-align: center;
  font-size: 18px;
}
