.loginPageWrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
}
.image {
  min-width: 950px;
  width: 60%;
  max-width: 1300px;
  height: auto;
}

.loginForm {
  min-width: 350px;
  min-height: 550px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
}

.loginMessage {
  font-size: 36px;
  font-weight: bold;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.input {
  font-size: 16px;
  padding: 1rem 0.5rem;
  border: none;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
  background: #fff;
}
::placeholder {
  color: #379efd;
  font-weight: bold;
  opacity: 0.5;
}

.button {
  font-size: 16px;
  color: white;
  padding: 1rem;
  border-radius: 6px;
  background: #379efd;
  transition: 0.5s;
}
.button:hover {
  color: #379efd;
  background: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: 0.5s;
}

.signUpPageButton {
  display: flex;
  gap: 0.5rem;
}

.singUpPage {
  color: black;
  font-weight: bold;
  text-decoration: none;
  transition: 0.5s;
  background: none;
}

.singUpPage:hover {
  color: #379efd;
  transition: 0.5s;
}
